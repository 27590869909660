const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/collect/:hash',
    name: 'collect',
    component: () => import(/* webpackChunkName: "collect" */ '../views/Collect.vue'),
    props: route => ({
      hash: route.params.hash,
    })
  },
  {
    path: '/collect/:hash/success',
    name: 'collect-success',
    component: () => import(/* webpackChunkName: "collect" */ '../views/CollectSuccess.vue'),
    props: route => ({
      hash: route.params.hash,
    })
  },
  {
    path: '/collect/:hash/cancelled',
    name: 'collect-cancelled',
    component: () => import(/* webpackChunkName: "collect" */ '../views/CollectCancelled.vue'),
    props: route => ({
      hash: route.params.hash,
    })
  },
  { 
    path: "*", 
    component: () => import('../views/PageNotFound.vue'),
  }
];

export default routes